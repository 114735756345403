import React, { useState } from 'react';
import { FormGroup, Row, Col, Collapse, CardBody, Card, Button } from 'reactstrap';
import ButtonActionComponent from './ButtonActionComponent';
import TextareaActionComponent from './TextAreaActionComponent';
import NumberActionComponent from './NumberActionComponent';
import DropdownActionComponent from './DropdownActionComponent';
import DefaultActionComponent from './DefaultActionComponent';
import FileActionComponent from './FileActionComponent';

// Helper function to chunk array into sections based on Order
function chunkIntoSections(actions) {
    const sections = [{ order: 1, title: 'Επιπλέον Ατομικά Στοιχεία' }, { order: 4, title: 'Παράλληλες ενέργειες για το ίδιο θέμα' }, { order: 6, title: 'Περιγραφή Παραπόνου' }, { order: 8, title: 'Στοιχεία Εξουσιοδοτημένου Προσώπου' }, { order: 14, title: 'Επισυναπτόμενα Έγγραφα' }];
    const chunks = sections.map((section, i) => {
        let nextOrder = sections[i + 1]?.order || Infinity;
        return {
            title: section.title,
            actions: actions.filter(action => action.action.Order >= section.order && action.action.Order < nextOrder)
        };
    });
    return chunks;
}

const DynamicActionsComponent = ({ dynamicData, updateInputValue, updateInputValueNumber, updateInputValueCascader, uploadFile, deleteFile, files, onCheckboxChange }) => {
    const sections = chunkIntoSections(dynamicData.actions);

    // Set the first three sections to be open by default
    const initialOpenSections = sections.map((_, index) => index < 3);
    const [openSections, setOpenSections] = useState(initialOpenSections);

    const [isChecked, setChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        const checkedStatus = event.target.checked;
        setChecked(checkedStatus);

        // Inform the parent component of the change
        onCheckboxChange(checkedStatus);
    };

    const toggleSection = index => {
        setOpenSections(prevState => prevState.map((isOpen, i) => i === index ? !isOpen : isOpen));
    };

    return (
        <div>
            <Row>
                <Col xl="12">
                    {sections.map((section, index) => (
                        <Card>
                            <CardBody>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h2 style={{ color: '#26575F' }}>{section.title}</h2>
                                    <Button size='sm' onClick={(e) => { e.preventDefault(); toggleSection(index); }}>
                                        {openSections[index] ? 'Απόκρυψη' : 'Προβολή'}
                                    </Button>
                                </div>
                                <Collapse isOpen={openSections[index]}>
                                    <hr />
                                    {section.actions.map(dynamicActions => (
                                        <FormGroup key={dynamicActions.action.Id}>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                                style={(index !== 2 && index !== 3) ? { paddingLeft: '14px' } : {}}
                                            >
                                                {dynamicActions.action.Name}
                                            </label>

                                            {dynamicActions.action.Type === 'textarea' && <TextareaActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} updateInputValue={updateInputValue} />}
                                            {dynamicActions.action.Type === 'number' && <NumberActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} updateInputValueNumber={updateInputValueNumber} />}
                                            {dynamicActions.action.Type === 'dropdown' && <DropdownActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} updateInputValueCascader={updateInputValueCascader} />}
                                            {dynamicActions.action.Type === 'file' && <FileActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} uploadFile={uploadFile} deleteFile={deleteFile} files={files} />}
                                            {(!['button', 'textarea', 'number', 'dropdown', 'file'].includes(dynamicActions.action.Type)) && <DefaultActionComponent dynamicData={dynamicData} dynamicActions={dynamicActions} updateInputValue={updateInputValue} />}
                                        </FormGroup>
                                    ))}
                                </Collapse>
                            </CardBody>
                        </Card>

                    ))}
                </Col>
            </Row>
            <br />
            <Row>
                <Col xl="12">
                    <ul style={{ listStyleType: 'circle' }}>
                        <li>Δηλώνω ότι το περιεχόμενο της αίτησης και των συνυποβαλλόμενων εγγράφων είναι αληθές και ότι συναινώ να λαμβάνω ενημέρωση για την υπόθεση μου, μέσω της πλατφόρμας ENOMOS, ή σε οποιαδήποτε από τις δηλωθείσες ανωτέρω διευθύνσεις (ταχυδρομική, e-mail) και τηλέφωνα.</li>
                        <li>Συμφωνώ να εξετάσει ο Ενεργειακός Διαμεσολαβητής την εν λόγω διαφορά σύμφωνα με τα αναφερόμενα στον ιστότοπο <a href="https://www.rae.gr/energiakos-diamesolavitis" target="_blank">www.rae.gr/energiakos-diamesolavitis</a> των οποίων δηλώνω ότι έλαβα γνώση.</li>
                        <li>Εξουσιοδοτώ τον Ενεργειακό Διαμεσολαβητή, στο πλαίσιο εξέτασης της διαφοράς, αντ' εμού και για λογαριασμό μου, να αιτείται και λαμβάνει από τον αναφερόμενο πάροχο ή διαχειριστή δικτύου τα αναγκαία κατά περίπτωση δεδομένα προσωπικού χαρακτήρα και στοιχεία που καλύπτονται από το προσωπικό απόρρητο, τα οποία με αφορούν, όπως ενδεικτικά αντίγραφα συμβάσεων, κινήσεις λογαριασμών, παραστατικά συναλλαγών, για τη χορήγηση των οποίων παρέχω ρητά τη συγκατάθεση μου.</li>
                        <li>Εξουσιοδοτώ τον Ενεργειακό Διαμεσολαβητή να παρέχει τις σχετικές με την υπόθεση μου πληροφορίες και έγγραφα στον αναφερόμενο πάροχο ή διαχειριστή δικτύου.</li>
                        <li>Έχω λάβει γνώση των κάτωθι:
                            <ul>
                                <li>Για να ασκήσετε τα δικαιώματά σας (άρθρα 12-22 ΓΚΠΔ) ή για οποιονδήποτε άλλο λόγο σχετικά με την επεξεργασία δεδομένων που διενεργεί ο Ενεργειακός Διαμεσολαβητής, μπορείτε να επικοινωνήσετε με τον Υπεύθυνο Προστασίας της ΡΑΑΕΥ στη διεύθυνση ηλεκτρονικής αλληλογραφίας dpo@raaey.gr ή στην ταχυδρομική διεύθυνση Πειραιώς 132, 11854 ΑΘΗΝΑ.</li>
                                <li>Σύμφωνα με τον Κανονισμό 2016/679, έχετε το δικαίωμα, εάν θεωρείτε ότι παραβιάζονται τα δικαιώματά σας όσον αφορά την προστασία των προσωπικών σας δεδομένων, να υποβάλετε καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα, η οποία εδρεύει στην Αθήνα (Κηφισίας 1-3, Τ.Κ. 115 23) και στο τηλέφωνο 2106475600 και φαξ 2106475628 ή στη διεύθυνση ηλεκτρονικής αλληλογραφίας complaints@dpa.gr.</li>
                            </ul>
                        </li>
                    </ul>

                    <div
                        style={{
                            backgroundColor: '#e6f2ff',
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                        }}
                    >
                        <p>Μελέτησα την ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΔΕΔΟΜΕΝΩΝ ΠΡΟΣΩΠΙΚΟΥ ΧΑΡΑΚΤΗΡΑ Ενεργειακού Διαμεσολαβητή που είναι αναρτημένη στον ιστότοπο <a href="https://www.rae.gr/energiakos-diamesolavitis" target="_blank">www.rae.gr/energiakos-diamesolavitis</a> και δηλώνω ότι την αποδέχομαι και ΣΥΝΑΙΝΩ στην επεξεργασία των δεδομένων μου προσωπικού χαρακτήρα από τον Ενεργειακό Διαμεσολαβητή.</p>
                        <label>
                            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} /> ΑΠΟΔΕΧΟΜΑΙ και ΣΥΝΑΙΝΩ
                        </label>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default DynamicActionsComponent;
