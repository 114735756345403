import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Helmet } from "react-helmet";
import '../../../common/css/newInstanceCustomCss.css'
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import { CommonLoading } from 'react-loadingg';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import EVOControlsHelper from "../../../common/EVOControlsHelper";
import ReactTooltip from 'react-tooltip';
import { ValidatorHelper } from "../../../common/ValidatorHelper"
import DynamicActionsComponent from '../../../components/DynamicActionsComponent'
import EnomosLogo from '../../../assets/img/brand/enomos-logo.jpg'
import EnomosPicture from '../../../assets/img/brand/enomos.png'
import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Label,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

import background from "../../../assets/img/header-background-2.jpg";


import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

import { Cascader } from 'antd';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images_tooltips_images = importAll(require.context('../tooltipimages/', false, /\.(png|jpe?g|svg)$/));

class newInstanceEnomos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormValid: false,
            deddieElevenDigits: false,
            isChecked: false,
            user: [],
            error: null,
            activeNav: 1,
            enabled: true,
            gasMaintainers: [],
            electrMaintainers: [],
            gasSuppliers: [],
            electrSuppliers: [],
            gasAndElectrSuppliers: [],
            selectedType: 0,
            selectedReceiver: 0,
            SupplierStr: '',
            SupplierStr2: '',
            wId: '',
            dropdownsActive: 0,
            instanceId: '',
            steps: [],
            files: [],
            fileUpload: null,
            instance: '',
            showModal: false,
            isYesSelected: false,
            serialNumber: null
        };
        this.deleteFile = this.deleteFile.bind(this);
        this.updateInputValueCascader = this.updateInputValueCascader.bind(this);
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;
        this.setState({ instanceId: handle });
        this.handleClick()
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {

                        this.setState({
                            isLoaded: true,
                            user: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
            fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/details/" + handle + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            serialNumber: result.SerialNumber,
                        });
                        console.log(this.state.serialNumber);
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )
        };
        this.handleShowModal()
    };

    handleShowModal = () => {
        this.setState({ showModal: true });
    }

    handleJustCloseModal = () => {
        this.setState({ showModal: false });
    }

    handleRedirect = () => {
        this.setState({ showModal: false });
        window.location.replace("/consumer/index/");
    }


    validateForm = () => {
        const { steps, isChecked } = this.state;
        let isFormValid = true;

        // Check if textareas and dropdowns for orders 1 to 7 are filled correctly
        steps.forEach(step => {
            step.actions.forEach(action => {
                if (action.action.Order >= 1 && action.action.Order <= 7) {
                    if (action.action.Type === 'textarea' && (!action.data.Data || action.data.Data.trim() === '')) {
                        isFormValid = false;
                    }
                    if (action.action.Type === 'dropdown' && (action.action.Order === 4 || action.action.Order === 5) && !['ΝΑΙ', 'ΟΧΙ'].includes(action.data.Data)) {
                        isFormValid = false;
                    }
                }
            });
        });

        // Check if checkbox is checked
        if (!isChecked) {
            isFormValid = false;
        }

        this.setState({ isFormValid });
    }

    handleClick = stepStatus => {
        var wId = '';

        this.setState({ dropdownsActive: 0 });

        axios.get(process.env.REACT_APP_API_LINK + "/api/enomos/initiliaze/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({
                    steps: res.data.stepsInfused,
                    fileUpload: null,
                });
                console.log(this.state.steps);
            })
    }

    handleCheckboxChange = (checkedStatus) => {
        this.setState({ isChecked: checkedStatus }, this.validateForm);
    }

    setSubmitButtonsEnabled(isEnabled) {
        //isEnabled = true; //bug fix 20221115 hot fix to enable button
        try {
            if (isEnabled) {
                document.getElementById('submit-button-1').removeAttribute('disabled');
                document.getElementById('submit-button-2').removeAttribute('disabled');
            }
            // else {
            //     document.getElementById('submit-button-1').setAttribute('disabled', '');
            //     document.getElementById('submit-button-2').setAttribute('disabled', '');
            // }

        }
        catch (ex) {
            console.error(ex);
        }
    }

    deleteInstance = stepStatus => {
        window.location.replace("/consumer/index");
    }


    deleteFile = (fileId) => {
        var self = this;
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/files/enomos/' + fileId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    axios.get(process.env.REACT_APP_API_LINK + "/api/files/enomos/initializestatus/" + this.state.instanceId + "/", {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                    })
                        .then(res => {
                            this.setState({
                                loading: false,
                                files: res.data
                            });
                        })
                })
        }
    }

    uploadFile = (evt, actionid) => {
        const formData = new FormData();
        var res = actionid.split("/");

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );

        // Details of the uploaded file 
        // console.log(evt.target.files[0]);
        // console.log(formData);


        fetch(
            process.env.REACT_APP_FILEMANAGER_LINK,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                // console.log('Success:', result);

                fetch(process.env.REACT_APP_API_LINK + '/api/files/enomos/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'Name': result[0].name,
                        'Path': result[0].path,
                        'Size': result[0].size,
                        'Type': result[0].type,
                        'InstanceId': this.state.instanceId,
                        'StepId': res[0],
                        'ActionId': res[1],
                        'UserType': 0,
                        'ReceiverType': 1
                    })
                })
                    .then(data => {
                        // console.log(data);

                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/enomos/initializestatus/" + this.state.instanceId + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                            })
                    })
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    updateInputValue = (evt) => {
        var res = evt.target.id.split("/");
        let data = [...this.state.steps];

        function checkifFilled(item) {
            let newTargetValue = evt.target.value ?? '';
            let stepActionId = evt.target.id.split("/")[0];
            if (stepActionId !== item.action.Id) {
                newTargetValue = item.data.Data;
            }

            if (item.data.Data !== '') {

                if (item.action.Name == 'Όνομα Κατόχου Παροχής*') {
                    if (newTargetValue.trim().length == 0) {
                        return false;
                    }
                    else {
                        return true;
                    }
                } else if (item.action.Description == 'Αριθμός Παροχής') {
                    if ((newTargetValue).length < 8) {
                        console.log('xaxa');
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (item.action.Target == 'afmlimit9') {
                    let value = item.data.Data;
                    let isValidAfm = ValidatorHelper.AFMValidator.IsValidAfm(value);
                    return isValidAfm;
                }
                else if (item.action.Target == 'aeriolimit14') {
                    console.log(item.data.Data)
                    if (/^\d{14}$/.test(item.data.Data) == false) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else
                    return true;

            }
        }

        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                let checkAction = this.state.steps[i].actions.slice(0, 9)
                let checkRequired = checkAction.filter(element => {
                    if (element.action.Required) return element
                })
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = evt.target.value;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
                checkRequired.forEach(element => {
                    if (element.data.Data == '') {
                        this.setSubmitButtonsEnabled(false);
                    }
                })
                if (checkRequired.every(checkifFilled)) {
                    this.setSubmitButtonsEnabled(true);
                }
                else {
                    this.setSubmitButtonsEnabled(false);
                }
            }
        }
        this.validateForm();
    }

    updateInputValueNumber = (evt, dynamicactions) => {
        if (dynamicactions.action.Target == 'limit14') {
            if ((evt.target.value).length > 14) {
                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            }
        }

        if (dynamicactions.action.Target == 'limit9') {
            if ((evt.target.value).length > 9) {
                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            }
        }

        if (dynamicactions.action.Target == 'afmlimit9') {
            ValidatorHelper.AFMValidator.OnUpdateInputValueValidation(evt);
        }
        if (dynamicactions.action.Target == 'aeriolimit14') {
            // if ((evt.target.value).length > 9) {
            //     evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            // }
            if ((evt.target.value).length > 14) {
                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            }

        }


        let energyKind = this.state.selectedType
        let secondReceiver = this.state.SupplierStr2
        var res = evt.target.id.split("/");
        let data = [...this.state.steps];

        function checkifFilled(item) {
            if (item.data.Data !== '') {

                if (item.action.Name == 'Αριθμός Παροχής*') {
                    if (energyKind != 2 && (evt.target.value).length < 8) {
                        return false;
                    }
                    else if (energyKind == 2 && (((evt.target.value).length < 11) || (evt.target.value).length > 11)) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (item.action.Target == 'afmlimit9') {
                    console.log(item.data.Data)
                    if (/^\d{9}$/.test(item.data.Data) == false) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (item.action.Target == 'aeriolimit14') {
                    console.log(item.data.Data)
                    if (/^\d{14}$/.test(item.data.Data) == false) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else
                    return true;

            }
        }
        // console.log(res);

        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                let checkAction = this.state.steps[i].actions.slice(0, 9)
                let checkRequired = checkAction.filter(element => {
                    if (element.action.Required) return element
                })
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = evt.target.value;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
                checkRequired.forEach(element => {
                    if (element.data.Data == '' && element.action.Required) {
                        this.setSubmitButtonsEnabled(false);
                    }
                })
                if (checkRequired.every(checkifFilled)) {
                    this.setSubmitButtonsEnabled(true);
                }
                else {
                    this.setSubmitButtonsEnabled(false);
                }
                // if (dynamicactions.action.Target == 'minimum8') {
                //     if ((evt.target.value).length < 8) {
                //         document.getElementById('submit-button-1').setAttribute('disabled', '');
                //         document.getElementById('submit-button-2').setAttribute('disabled', '');
                //     }
                // }
            }
        }
    }

    updateInputValueCascader = (value, dynamicActions) => {
        let data = [...this.state.steps];
        let temp = value.join(' / ');

        function checkifFilled(item) {
            return item.data.Data !== '';
        }

        data.forEach((step) => {
            step.actions.forEach((action) => {
                if (action.action.Id === dynamicActions.action.Id) {
                    action.data.Data = temp;
                    let checkAction = step.actions.slice(0, 9);
                    let checkRequired = checkAction.filter(element => element.action.Required);
                    checkRequired.forEach(element => {
                        if (element.data.Data === '' && element.action.Required) {
                            this.setSubmitButtonsEnabled(false);
                        }
                    });
                    if (checkRequired.every(checkifFilled)) {
                        this.setSubmitButtonsEnabled(true);
                    }
                }
            });
        });

        this.setState({
            steps: data
        });
        console.log(this.state.steps);
    }


    submitInstance = stepStatus => {
        console.log(this.state.wId);

        if (localStorage.getItem('token')) {

            fetch(process.env.REACT_APP_API_LINK + '/api/enomos/new/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'instanceId': this.state.instanceId
                })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    fetch(process.env.REACT_APP_API_LINK + '/api/enomos/submit/' + data.Id + '/', {
                        method: 'POST',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        }),
                        body: JSON.stringify(this.state.steps[stepStatus - 1])
                    })
                        .then(data => {
                            // setTimeout(function () {
                            //     window.location.replace("/consumer/instancedetails/" + data.Id);
                            // }, 2500);
                            window.location.replace("/consumer/instance/" + this.state.instanceId);
                        })
                })
        }
    }

    render() {
        return (
            <>
                {/* <Helmet>
                    <style>{`
              .container-fluid {
                background: linear-gradient(to right, white, rgb(38, 87, 95)) !important;
              }
            `}</style>
                </Helmet> */}

                <Modal isOpen={this.state.showModal} toggle={this.handleJustCloseModal}
                    className="modal-90w" fade={false}
                    style={{ maxWidth: '90%', width: 'auto' }}> {/* Adjusted width here */}

                    <ModalHeader toggle={this.handleJustCloseModal}></ModalHeader>
                    <ModalBody>
                        <div>
                            <ul style={{ listStyleType: 'circle' }}>
                                <li>Δηλώνω ότι το περιεχόμενο της αίτησης και των συνυποβαλλόμενων εγγράφων είναι αληθές και ότι συναινώ να λαμβάνω ενημέρωση για την υπόθεση μου, μέσω της πλατφόρμας ENOMOS, ή σε οποιαδήποτε από τις δηλωθείσες ανωτέρω διευθύνσεις (ταχυδρομική, e-mail) και τηλέφωνα.</li>
                                <li>Συμφωνώ να εξετάσει ο Ενεργειακός Διαμεσολαβητής την εν λόγω διαφορά σύμφωνα με τα αναφερόμενα στον ιστότοπο <a href="https://www.rae.gr/energiakos-diamesolavitis" target="_blank">www.rae.gr/energiakos-diamesolavitis</a> των οποίων δηλώνω ότι έλαβα γνώση.</li>
                                <li>Εξουσιοδοτώ τον Ενεργειακό Διαμεσολαβητή, στο πλαίσιο εξέτασης της διαφοράς, αντ' εμού και για λογαριασμό μου, να αιτείται και λαμβάνει από τον αναφερόμενο πάροχο ή διαχειριστή δικτύου τα αναγκαία κατά περίπτωση δεδομένα προσωπικού χαρακτήρα και στοιχεία που καλύπτονται από το προσωπικό απόρρητο, τα οποία με αφορούν, όπως ενδεικτικά αντίγραφα συμβάσεων, κινήσεις λογαριασμών, παραστατικά συναλλαγών, για τη χορήγηση των οποίων παρέχω ρητά τη συγκατάθεση μου.</li>
                                <li>Εξουσιοδοτώ τον Ενεργειακό Διαμεσολαβητή να παρέχει τις σχετικές με την υπόθεση μου πληροφορίες και έγγραφα στον αναφερόμενο πάροχο ή διαχειριστή δικτύου.</li>
                                <li>Έχω λάβει γνώση των κάτωθι:
                                    <ul>
                                        <li>Για να ασκήσετε τα δικαιώματά σας (άρθρα 12-22 ΓΚΠΔ) ή για οποιονδήποτε άλλο λόγο σχετικά με την επεξεργασία δεδομένων που διενεργεί ο Ενεργειακός Διαμεσολαβητής, μπορείτε να επικοινωνήσετε με τον Υπεύθυνο Προστασίας της ΡΑΑΕΥ στη διεύθυνση ηλεκτρονικής αλληλογραφίας dpo@raaey.gr ή στην ταχυδρομική διεύθυνση Πειραιώς 132, 11854 ΑΘΗΝΑ.</li>
                                        <li>Σύμφωνα με τον Κανονισμό 2016/679, έχετε το δικαίωμα, εάν θεωρείτε ότι παραβιάζονται τα δικαιώματά σας όσον αφορά την προστασία των προσωπικών σας δεδομένων, να υποβάλετε καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα, η οποία εδρεύει στην Αθήνα (Κηφισίας 1-3, Τ.Κ. 115 23) και στο τηλέφωνο 2106475600 και φαξ 2106475628 ή στη διεύθυνση ηλεκτρονικής αλληλογραφίας complaints@dpa.gr.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleRedirect}>
                            Διαφωνώ
                        </Button>
                        <Button color="success" onClick={this.handleJustCloseModal}>
                            Συμφωνώ
                        </Button>
                    </ModalFooter>
                </Modal>
                <div className="new-instance-enomos" style={{ backgroundImage: `url(${background})`, paddingBottom: '50px' }}>
                    <InstanceHeader />
                    <div className="header pb-4">
                        <Container fluid>
                            <div className="header-body">
                                <Row>
                                    <Col lg="8" xl="3">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    {/* Image Column */}
                                                    <Col xl="3">
                                                        <img src={EnomosLogo} alt="Description" style={{ maxWidth: '100%', height: 'auto' }} />
                                                    </Col>

                                                    {/* Text Column */}
                                                    <Col xl="9">
                                                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                            {/* Title here if needed */}
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0" style={{ color: "#1B1D51" }}>
                                                            Αριθμός Αιτήματος: MyRAE-{this.state.serialNumber}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="8" xl="3">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    {/* Image Column */}
                                                    <Col xl="4">
                                                        <img src={EnomosPicture} alt="Description" style={{ maxWidth: '380%', height: 'auto' }} />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>
                            </div>
                        </Container>
                    </div>


                    <Container className="mt-4" fluid>

                        {this.state.steps.map((dynamicData) =>
                            <>
                                {dynamicData.step.Status == 1 ?
                                    <>
                                        {dynamicData.step.Conditional ?
                                            <Container className="mt-2" fluid>
                                                <Row className="mt-5">
                                                    <Col className="order-xl-1" xl="12">
                                                        <Card className="bg-secondary shadow">
                                                            <CardHeader className="bg-white border-0">
                                                                <Row className="align-items-center">
                                                                    <Col>
                                                                        <h2 style={{ color: '#26575F' }}>Yποβάλλετε την αίτηση σας ηλεκτρονικά, αφού συμπληρώσετε τα επιπλέον στοιχεία που ζητούνται παρακάτω</h2>
                                                                        <h5 style={{ color: '#26575F' }}>*Είναι υποχρεωτική η συμπλήρωση όλων των ανοιχτών πεδίων, προκειμένου να γίνει υποβλητέα η αίτηση</h5>
                                                                    </Col>

                                                                </Row>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Form>
                                                                    <div className="pl-lg-4">
                                                                        <DynamicActionsComponent
                                                                            dynamicData={dynamicData}
                                                                            updateInputValue={this.updateInputValue}
                                                                            updateInputValueNumber={this.updateInputValueNumber}
                                                                            updateInputValueCascader={this.updateInputValueCascader}
                                                                            uploadFile={this.uploadFile}
                                                                            deleteFile={this.deleteFile}
                                                                            files={this.state.files}
                                                                            onCheckboxChange={this.handleCheckboxChange}
                                                                        />
                                                                    </div>
                                                                </Form>
                                                            </CardBody>
                                                            <CardFooter className="bg-white border-0">
                                                                <Row className="align-items-center">
                                                                    <Col xs="8">
                                                                        {/* <h3 className="mb-0">{dynamicData.step.Name}</h3> */}
                                                                    </Col>
                                                                    <Col className="text-right" xs="4">
                                                                        <Button
                                                                            color="danger"
                                                                            onClick={() => { this.deleteInstance() }}
                                                                            size="sm"
                                                                        >
                                                                            Ακύρωση
                                                                        </Button>
                                                                        <Button
                                                                            color="success"
                                                                            onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                                                            size="sm"
                                                                            id="submit-button-2"
                                                                            disabled={!this.state.isFormValid}
                                                                        >
                                                                            Υποβολή
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <Col xs="9">
                                                                        <h3 className="mb-0">Εάν επιθυμείτε να στείλετε την αίτησή σας ταχυδρομικά, κατεβάστε τη σχετική φόρμα που πρέπει να συμπληρώσετε</h3>
                                                                    </Col>
                                                                    <Col className="text-right" xs="3">
                                                                        <Button
                                                                            color="warning"
                                                                            // onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                                                            size="sm"
                                                                        >
                                                                            ΕΝΤΥΠΗ ΑΙΤΗΣΗ ΔΙΑΜΕΣΟΛΑΒΗΣΗΣ
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </CardFooter>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            : <Container className="mt-2" fluid>
                                                <Row className="mt-5">
                                                    <Col className="order-xl-1" xl="12">
                                                        <Card className="bg-secondary shadow">
                                                            <CardBody>
                                                                <Form>
                                                                    <div className="pl-lg-4">
                                                                        <Row>
                                                                            <Col lg="12">
                                                                                <p style={{ fontSize: '18px' }}>Θα ενημερωθείτε από τον Έλεγχο Πορείας για το επόμενο στάδιο της αίτησης που θα χρειαστεί να συμπληρώσετε.</p>
                                                                                <Button
                                                                                    color="primary"
                                                                                    href={"/consumer/instance/" + this.state.instance.Id}
                                                                                    size="lg"
                                                                                >
                                                                                    Έλεγχος πορείας
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Form>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                    </>
                                    :
                                    <> </>
                                }
                            </>
                        )}

                    </Container>
                </div>
            </>
        );
    }
}

export default newInstanceEnomos;
