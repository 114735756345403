import { Steps, Panel, Notification, Timeline, Paragraph } from 'rsuite';
import { saveAs } from 'file-saver';
import React, { useState } from "react";
import ReactTooltip from 'react-tooltip';
import {
    Collapse,
    Card,
    Form,
    FormGroup,
    Input,
    Table,
    CardHeader,
    CardTitle,
    CardBody,
    Container,
    Row,
    Col,
    Button
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from 'react-loadingg';
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import background from "../../../assets/img/header-background.jpg";
import 'assets/css/rsuite-default.min.css';
import axios from 'axios';

class ConsumerInstanceEnomos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commentDisabled: true,
            isOpen: false,
            error: null,
            step: 0,
            details: [],
            instance: [],
            workflow: [],
            fileUpload: null,
            steps: [],
            user: [],
            files: [],
            consumerFiles: [],
            receiverFiles: [],
            replies: [],
            extra: [],
            answerStep: '',
            answerAction: '',
            answer: '',
            filesUpload: [],
            showAnswer: 0,
            hasReplies: 0,
            maxlimitReplies: false,
            loading: true,
            blocked: 0,
            declinedbyConsumer: false
        };
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;
        // console.log(handle);

        if (localStorage.getItem('token')) {
            const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/enomos/details/" + handle + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    this.setState({
                        instance: res.data,
                        details: res.data
                    });
                    this.setState({
                        step: this.state.instance.Status
                    });
                });

            await fetch(process.env.REACT_APP_API_LINK + "/api/enomos/extra/" + handle + "/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        // console.log(result);
                        this.setState({
                            extra: result,
                        });
                    },
                    (error) => {
                        // console.log(error);
                        this.setState({
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        // console.log(result);
                        this.setState({
                            user: result,
                        });
                    },
                    (error) => {
                        // console.log(error);
                        this.setState({
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/replies/enomos/all/" + handle + "/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        // console.log(result);
                        this.setState({
                            replies: result.replies,
                            hasReplies: result.hasReplies
                        });

                        if (result.hasReplies) {
                            var counter = 0;
                            result.replies.forEach(function (arrayItem) {
                                arrayItem.user = result.users[counter];
                                counter++;

                            });
                            this.setState({
                                replies: result.replies,
                            });

                        }

                    },
                    (error) => {
                        // console.log(error);
                        this.setState({
                            error
                        });
                    }
                )

            await fetch(process.env.REACT_APP_API_LINK + "/api/files/enomos/instance/" + handle + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            files: result,
                            loading: false
                        });
                        let allFiles = this.state.files
                        let filteredConsumerFiles = allFiles.filter(element => {
                            if (element.UserId == this.state.instance.UserId) return element
                        })
                        let filteredReceiverFiles = allFiles.filter(element => {
                            if (element.UserType == 1 && element.ReceiverType == 0) return element
                        })
                        this.setState({
                            consumerFiles: filteredConsumerFiles,
                            receiverFiles: filteredReceiverFiles
                        });
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )
        };
    }

    downloadAllFiles = (files) => {
        files.forEach(f => {
            fetch(f.Link, {
                headers: {
                    'Content-Type': f.Type
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    saveAs(blob, f.FileName);
                });
        })
    };

    handleDownload = (link, contentType, name) => {
        fetch(link, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, name);
            });
    };


    updateAnswer(evt) {
        if (evt.target.value != '') {
          this.setState({ commentDisabled: false })
        }
        else this.setState({ commentDisabled: true })
        this.setState({
            answer: evt.target.value
        })
    }



    sendAnswer(evt) {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/replies/enomos/new/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'InstanceId': this.state.instance.Id,
                    'UserType': 0,
                    'Message': this.state.answer,
                    'ReceiverType': 1
                })
            })
                .then(data => {
                    fetch(process.env.REACT_APP_API_LINK + "/api/replies/enomos/all/" + this.state.instance.Id + "/", {
                        method: 'get',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        })
                    })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                // console.log(result);
                                this.setState({
                                    replies: result.replies
                                });

                                var counter = 0;
                                result.replies.forEach(function (arrayItem) {
                                    arrayItem.user = result.users[counter];
                                    counter++;

                                });
                                this.setState({
                                    replies: result.replies,
                                });
                                window.location.reload(false);

                            },
                            (error) => {
                                // console.log(error);
                                this.setState({
                                    error
                                });
                            }
                        )
                })
        }
    }

    updateFile(evt) {
        this.setState({ fileUpload: evt.target.files[0] });
    }

    deleteFile = fileId => {
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    axios.get(process.env.REACT_APP_API_LINK + "/api/files/instance/" + this.state.instance.Id + "/", {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                    })
                        .then(res => {
                            this.setState({
                                loading: false,
                                files: res.data
                            });
                            // console.log(res);
                        })
                })
        }
    }

    uploadFile(evt) {
        // this.setState({ fileUpload: evt.target.files[0] });
        const formData = new FormData();
        // var res = evt.split("/");

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );

        fetch(
            process.env.REACT_APP_FILEMANAGER_LINK,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                // console.log('Success:', result);

                fetch(process.env.REACT_APP_API_LINK + '/api/files/enomos/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjcm1hZG1pbkBkZWRhLmdyIiwiZXhwIjoxNjI0OTcyNzExfQ.EPp7fXdyHnPUuQGili4QwH1-FNJo_I0z-M-eNdYSSTg',
                    }),
                    body: JSON.stringify({
                        'Name': result[0].name,
                        'Path': result[0].path,
                        'Size': result[0].size,
                        'Type': result[0].type,
                        'InstanceId': this.state.instance.Id,
                        'StepId': '674d15b5-2717-40c2-b119-489572b8501a',
                        'ActionId': '3afe3b80-db44-4407-93c8-c17dde55221b',
                        'UserType': 0,
                        'ReceiverType': 1
                    })
                })
                    .then(data => {
                        // console.log(data);

                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/enomos/status/" + this.state.instance.Id + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                            // headers: { Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjcm1hZG1pbkBkZWRhLmdyIiwiZXhwIjoxNjI0OTcyNzExfQ.EPp7fXdyHnPUuQGili4QwH1-FNJo_I0z-M-eNdYSSTg'}
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                                let allFiles = this.state.files
                                let filteredConsumerFiles = allFiles.filter(element => {
                                    if (element.UserId == this.state.instance.UserId) return element
                                })
                                let filteredReceiverFiles = allFiles.filter(element => {
                                    if (element.UserType == 1 && element.ReceiverType == 0) return element
                                })
                                this.setState({
                                    consumerFiles: filteredConsumerFiles,
                                    receiverFiles: filteredReceiverFiles
                                });
                                // console.log(res);
                            })

                        fetch(process.env.REACT_APP_API_LINK + "/api/files/enomos/instance/" + this.state.instance.Id + '/', {
                            method: 'get',
                            headers: new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type': 'application/x-www-form-urlencoded'
                            })
                        })
                            .then(res => res.json())
                            .then(
                                (result) => {
                                    this.setState({
                                        files: result,
                                        loading: false
                                    });
                                    let allFiles = this.state.files
                                    let filteredConsumerFiles = allFiles.filter(element => {
                                        if (element.UserId == this.state.instance.UserId) return element
                                    })
                                    let filteredReceiverFiles = allFiles.filter(element => {
                                        if (element.UserType == 1 && element.ReceiverType == 0) return element
                                    })
                                    this.setState({
                                        consumerFiles: filteredConsumerFiles,
                                        receiverFiles: filteredReceiverFiles
                                    });
                                },
                                (error) => {
                                    this.setState({
                                        error
                                    });
                                }
                            )
                    })
            });
    }

    toggleCollapse = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen, // Toggle the isOpen state
        }));
    };


    render() {
        if (this.state.loading) {
            return (
                <>
                    <GenericHeader />
                    <CommonLoading />
                </>
            )
        }
        else {
            return (
                <>
                    <InstanceHeader />
                    {/* <div className="header pb-4" style={{background: "linear-gradient(to right, #FFFFFF, #4A002B)"}}> */}
                    <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
                        <Container fluid>
                            <div className="header-body">
                                <Row>
                                    <Col lg="8" xl="12">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            Υποβολή Αιτήματος
                                                        </span>
                                                        <CardBody>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Αριθμός Αιτήματος: </span> &nbsp; {this.state.instance.SerialNumber}</Row>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Ημερομηνία Υποβολής: </span> &nbsp; {Moment(this.state.instance.StartedAt).locale('el').format('dddd, DD MMM YYYY')}</Row>
                                                        </CardBody>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                    <Container className="mt--4" fluid>
                        <Row className="mt-5">
                            <Col className="mb-5 mb-xl-0" xl="12">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">Πορεία Αιτήματος</h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <Steps current={this.state.step}>
                                                <Steps.Item title="Υποβολή Αιτήματος" description="Το αίτημα υποβλήθηκε." />
                                                <Steps.Item title="Διαχείριση αιτήματος" description="Λήψη και έλεγχος του αιτήματός σας." />
                                                <Steps.Item title="Ολοκλήρωση αιτήματος" description="Το αίτημα ολοκληρώθηκε." />
                                            </Steps>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col className="order-xl-1" xl="6">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h4 className="mb-0">Στοιχεία Αιτήματος</h4>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <h6 className="heading-small text-muted mb-4">
                                                Βασικες πληροφοριες
                                            </h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Πατρώνυμο
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Πατρώνυμο"
                                                                defaultValue={this.state.extra.fathername}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Αριθμός Ταυτότητας
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Αριθμός Ταυτότητας"
                                                                defaultValue={this.state.extra.id}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Ταχυδρομική Διεύθυνση
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Ταχυδρομική Διεύθυνση"
                                                                defaultValue={this.state.extra.postalcode}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row><Row className='mt-4'>

                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Το αναφερόμενο θέμα αποτελεί αντικείμενο δικαστικών διαδικασιών;*
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                defaultValue={this.state.extra.courtproceedings}
                                                                placeholder="Το αναφερόμενο θέμα αποτελεί αντικείμενο δικαστικών διαδικασιών;"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Έχετε απευθυνθεί σε άλλον φορέα εναλλακτικής επίλυσης διαφορών;
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Έχετε απευθυνθεί σε άλλον φορέα εναλλακτικής επίλυσης διαφορών;"
                                                                defaultValue={this.state.extra.advocate}
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Παρακαλούμε περιγράψτε το παράπονο σας και τους λόγους που μετά τις απαντήσεις που λάβατε μέσω του myRAE επιθυμείτε να προχωρήσετε στις υπηρεσίες του Ενεργειακού Διαμεσολαβητή
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Παρακαλούμε περιγράψτε το παράπονο σας και τους λόγους που μετά τις απαντήσεις που λάβατε μέσω του myRAE επιθυμείτε να προχωρήσετε στις υπηρεσίες του Ενεργειακού Διαμεσολαβητή"
                                                                defaultValue={this.state.extra.reasoning}
                                                                type="textarea"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Παρακαλούμε περιγράψτε τι είναι αυτό που ζητάτε από τον Ενεργειακό Διαμεσολαβητή. Ποια είναι η πρόταση/απαίτηση σας για την επίλυση του θέματος σας.
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Παρακαλούμε περιγράψτε τι είναι αυτό που ζητάτε από τον Ενεργειακό Διαμεσολαβητή. Ποια είναι η πρόταση/απαίτηση σας για την επίλυση του θέματος σας."
                                                                defaultValue={this.state.extra.suggestions}
                                                                type="textarea"
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="order-xl-1" xl='12'>
                                                        <Card className="bg-secondary shadow">
                                                            <CardHeader className="bg-white border-0">
                                                                <Row className="align-items-center">
                                                                    <Col xs="6">
                                                                        <h3 className="mb-0">Στοιχεία Εξουσιοδοτημένου Προσώπου</h3>
                                                                    </Col>
                                                                    <Col className="text-right" xs="6">
                                                                        {!this.state.isOpen ?
                                                                            <>
                                                                                <Button
                                                                                    className="btn btn-link" // You can style this to your liking
                                                                                    onClick={this.toggleCollapse}
                                                                                    size='sm'
                                                                                    color='info'
                                                                                >
                                                                                    Προβολή στοιχείων
                                                                                </Button>
                                                                            </> : <>
                                                                                <Button
                                                                                    className="btn btn-link" // You can style this to your liking
                                                                                    onClick={this.toggleCollapse}
                                                                                    size='sm'
                                                                                    color='info'
                                                                                >
                                                                                    Απόκρυψη στοιχείων
                                                                                </Button>
                                                                            </>}
                                                                    </Col>
                                                                </Row>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Form>
                                                                    <Collapse isOpen={this.state.isOpen}>

                                                                        <Row>
                                                                            <Col lg="6">
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="form-control-label"
                                                                                        htmlFor="input-email"
                                                                                    >
                                                                                        Όνομα
                                                                                    </label>
                                                                                    <Input
                                                                                        className="form-control-alternative"
                                                                                        id="input-email"
                                                                                        placeholder="Όνομα"
                                                                                        defaultValue={this.state.extra.authorizedname}
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col lg="6">
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="form-control-label"
                                                                                        htmlFor="input-email"
                                                                                    >
                                                                                        Επώνυμο
                                                                                    </label>
                                                                                    <Input
                                                                                        className="form-control-alternative"
                                                                                        id="input-email"
                                                                                        placeholder="Επώνυμο"
                                                                                        defaultValue={this.state.extra.authorizedsurname}
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg="6">
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="form-control-label"
                                                                                        htmlFor="input-email"
                                                                                    >
                                                                                        Πατρώνυμο
                                                                                    </label>
                                                                                    <Input
                                                                                        className="form-control-alternative"
                                                                                        id="input-email"
                                                                                        placeholder="Πατρώνυμο"
                                                                                        defaultValue={this.state.extra.authorizedfathername}
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col lg="6">
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="form-control-label"
                                                                                        htmlFor="input-email"
                                                                                    >
                                                                                        Αριθμός Ταυτότητας
                                                                                    </label>
                                                                                    <Input
                                                                                        className="form-control-alternative"
                                                                                        id="input-email"
                                                                                        placeholder="Αριθμός Ταυτότητας"
                                                                                        defaultValue={this.state.extra.authorizedid}
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg="6">
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="form-control-label"
                                                                                        htmlFor="input-email"
                                                                                    >
                                                                                        ΑΦΜ
                                                                                    </label>
                                                                                    <Input
                                                                                        className="form-control-alternative"
                                                                                        id="input-email"
                                                                                        placeholder="ΑΦΜ"
                                                                                        defaultValue={this.state.extra.authorizedafm}
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col lg="6">
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="form-control-label"
                                                                                        htmlFor="input-email"
                                                                                    >
                                                                                        Ταχυδρομική Διεύθυνση
                                                                                    </label>
                                                                                    <Input
                                                                                        className="form-control-alternative"
                                                                                        id="input-email"
                                                                                        placeholder="Ταχυδρομική Διεύθυνση"
                                                                                        defaultValue={this.state.extra.authorizedpostalcode}
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </Collapse>
                                                                </Form>

                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col md="6">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Εγγραφα Καταναλωτη
                                                        </h6>
                                                    </Col>
                                                    {this.state.consumerFiles.length > 0 &&
                                                        <>
                                                            <Col md="6">
                                                                <Button
                                                                    color="success"
                                                                    size='sm'
                                                                    onClick={() => { this.downloadAllFiles(this.state.consumerFiles) }}
                                                                >
                                                                    Λήψη όλων
                                                                </Button>
                                                            </Col>
                                                        </>}

                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Ονομα αρχειου</th>
                                                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                <th scope="col">Ενεργειες</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.consumerFiles.map((dynamicFiles) =>
                                                                    <tr>
                                                                        <td>{dynamicFiles.FileName}</td>
                                                                        <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                                                        <td>
                                                                            <Button
                                                                                color="primary"
                                                                                href={dynamicFiles.Link}
                                                                                target="_blank"
                                                                                title="Προβολή"
                                                                                className="btn-sm btn-primary"
                                                                            >
                                                                                <i className="fas fa-eye" />
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                                                                color="success"
                                                                                size='sm'
                                                                                title="Λήψη"
                                                                            >
                                                                                <i className="fas fa-download" />
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="order-xl-1" xl="6">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="12">
                                                <h4 className="mb-0">Ανταλλαγή Μηνυμάτων</h4>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.hasReplies &&
                                            <Col md="11" style={{ height: "38vh", overflowY: "scroll" }}>
                                                {this.state.replies
                                                    .filter(dynamicReplies =>
                                                        (dynamicReplies.UserType === 0 || dynamicReplies.UserType === 1) &&
                                                        (dynamicReplies.ReceiverType === 0 || dynamicReplies.ReceiverType === 1)
                                                    )
                                                    .map(dynamicReplies => (
                                                        <div
                                                            className="container"
                                                            style={{
                                                                textAlign: dynamicReplies.UserType === 0 ? "right" : "left",
                                                                background: dynamicReplies.UserType === 0 ? "powderblue" : "lightgray",
                                                                color: "black",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            <strong
                                                                style={{ margin: "0px" }}
                                                                className={dynamicReplies.UserType === 0 ? "time-right" : "time-left"}
                                                            >
                                                                {dynamicReplies.user}
                                                            </strong>
                                                            <p
                                                                style={{ margin: "0px" }}
                                                                className={dynamicReplies.UserType === 0 ? "time-right" : "time-left"}
                                                            >
                                                                {Moment(dynamicReplies.DateCreated)
                                                                    .locale("el")
                                                                    .format("DD MMM YYYY HH:mm:ss")}
                                                            </p>
                                                            <h4>
                                                                <i>{dynamicReplies.Message}</i>
                                                            </h4>
                                                        </div>
                                                    ))}
                                            </Col>
                                        }

                                    </CardBody>
                                    < hr />
                                    <Row className="align-items-center">
                                        <Col xs="11">
                                            <div className="pl-lg-4" style={{ width: '100%' }}>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Νεο Σχολιο
                                                </h6>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-email"
                                                    placeholder="Σχόλιο"
                                                    type="textarea"
                                                    onChange={evt => this.updateAnswer(evt)}
                                                />
                                                <br />
                                                <Col lg="10">
                                                    <label className="btn-sm btn-primary" htmlFor='file-answer' >
                                                        Επιλογή αρχείου
                                                    </label>
                                                    <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative'} aria-hidden="true" style={{ marginLeft: '5px', marginTop: '3px' }}></i>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id='file-answer'
                                                        style={{ visibility: "hidden" }}
                                                        type='file'
                                                        onChange={evt => this.uploadFile(evt)}
                                                    />


                                                    <ReactTooltip id={'info-form-control-alternative'} type='info'>
                                                        <span>Μέγιστο μέγεθος αρχείο 5ΜΒ.</span>
                                                        <div id="imageattooltip"></div>
                                                    </ReactTooltip>
                                                    {/* <Button
                                      color="primary"
                                      onClick={evt => this.uploadFile()}
                                      size="sm"
                                    >
                                      Επισύναψη
                                    </Button> */}
                                                </Col>
                                                <br />
                                                <Button
                                                    color="success"
                                                    onClick={evt => this.sendAnswer()}
                                                    size="sm"
                                                    style={{ width: '100%' }}
                                                    disabled={this.state.commentDisabled}
                                                >
                                                    Αποστολή
                                                </Button>
                                            </div>
                                            < hr />
                                            <div className="pl-lg-4" style={{ width: '100%' }}>
                                                <Row>
                                                    <Col md="6">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Εγγραφα Διαμεσολαβητη
                                                        </h6>
                                                    </Col>
                                                    {this.state.receiverFiles.length > 1 &&
                                                        <>
                                                            <Col md="6">
                                                                <Button
                                                                    color="success"
                                                                    size='sm'
                                                                    onClick={() => { this.downloadAllFiles(this.state.receiverFiles) }}
                                                                >
                                                                    Λήψη όλων
                                                                </Button>
                                                            </Col>
                                                        </>}

                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Ονομα αρχειου</th>
                                                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                <th scope="col">Ενεργειες</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.receiverFiles.map((dynamicFiles) =>
                                                                    <tr>
                                                                        <td>{dynamicFiles.FileName}</td>
                                                                        <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                                                        <td>
                                                                            <Button
                                                                                color="primary"
                                                                                href={dynamicFiles.Link}
                                                                                target="_blank"
                                                                                title="Προβολή"
                                                                                className="btn-sm btn-primary"
                                                                            >
                                                                                <i className="fas fa-eye" />
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                                                                color="success"
                                                                                size='sm'
                                                                                title="Λήψη"
                                                                            >
                                                                                <i className="fas fa-download" />
                                                                            </Button>
                                                                            {this.state.user.PersonRole == 'Admin' ?
                                                                                <>
                                                                                    <Button
                                                                                        color="danger"
                                                                                        onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                        size="sm"
                                                                                        title="Διαγραφή"
                                                                                    >
                                                                                        <i className="fas fa-trash" />
                                                                                    </Button>
                                                                                </> : <> </>}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                </Card>
                            </Col>

                            {this.state.instance.Status > 2 ?
                                <Col className="order-xl-1" xl="6">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h4 className="mb-0">Απάντηση Αιτήματος - Περαιτέρω Ενέργειες</h4>
                                                </Col>
                                                <Col className="text-right" xs="4">
                                                    {/* <Button
                              href="/admin/instancesettings"
                              // style={{ backgroundColor: this.state.yellow }}
                              // color={this.state.yellow}
                              color='primary'
                              size="sm"
                            >
                              Ρυθμίσεις
                            </Button> */}
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Col md="12">
                                                <h6 className="heading-small text-muted mb-2">
                                                    Απαντηση Προμηθευτη / Διαχειριστη
                                                </h6>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-email"
                                                    defaultValue={this.state.extra.reply}
                                                    placeholder="Απάντηση Παραλήπτη"
                                                    type="textarea"
                                                    disabled={true}
                                                />
                                            </Col>
                                            <br />

                                            {this.state.hasReplies ?
                                                <Card className="bg-secondary shadow">
                                                    <CardHeader className="bg-white border-0">
                                                        <Row className="align-items-center">
                                                            <Col xs="8">
                                                                <h4 className="mb-0">Ανταλλαγή Μηνυμάτων</h4>
                                                            </Col>
                                                            <Col className="text-right" xs="4">
                                                                {/* <Button
                                      color="primary"
                                      href={"/admin/instance/" + this.state.details.Id}
                                      size="sm"
                                    >
                                      Βήματα αιτήματος
                                    </Button> */}
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>

                                                    <CardBody>
                                                        <Col md="12">
                                                            {this.state.replies.map((dynamicReplies) =>
                                                                <div class="container" style={{ textAlign: dynamicReplies.UserType == 1 ? "left" : "right", background: dynamicReplies.UserType == 1 ? "lightgray" : "powderblue", color: "black", borderRadius: "8px" }}>
                                                                    <strong style={{ margin: "0px" }} class={dynamicReplies.UserType == 1 ? "time-right" : "time-left"}>{dynamicReplies.user}</strong>
                                                                    <p style={{ margin: "0px" }} class={dynamicReplies.UserType == 1 ? "time-left" : "time-right"}>{Moment(dynamicReplies.DateCreated).locale('el').format('DD MMM YYYY HH:mm:ss')}</p>
                                                                    <h4><i>{dynamicReplies.Message}</i></h4>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </CardBody>
                                                </Card>
                                                : <> </>}

                                            <br />

                                            {this.state.user.Email != 'deletedusers@rae.gr' ?
                                                <>

                                                    <Row className="align-items-center">
                                                        {this.state.showAnswer && !this.state.instance.Completed ?
                                                            <>
                                                                <div className="pl-lg-4" style={{ width: '100%' }}>
                                                                    <h6 className="heading-small text-muted mb-4">
                                                                        Yποβαλετε το νεο σχολιο σας
                                                                    </h6>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-email"
                                                                        placeholder="Σχόλιο"
                                                                        type="textarea"
                                                                        onChange={evt => this.updateAnswer(evt)}
                                                                    />
                                                                    <br />
                                                                    <Col lg="10">
                                                                        <label className="btn-sm btn-primary" htmlFor='file-answer' >
                                                                            Επιλογή αρχείου
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id='file-answer'
                                                                            // defaultValue={ dynamicActions.data.Data }
                                                                            // placeholder={dynamicActions.action.Description}
                                                                            style={{ visibility: "hidden" }}
                                                                            type='file'
                                                                            onChange={evt => this.uploadFile(evt)}
                                                                        />
                                                                        {/* <Button
                                      color="primary"
                                      onClick={evt => this.uploadFile()}
                                      size="sm"
                                    >
                                      Επισύναψη
                                    </Button> */}
                                                                    </Col>
                                                                    <br />
                                                                    <Button
                                                                        color="success"
                                                                        onClick={evt => this.sendAnswer()}
                                                                        size="sm"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        Aποστολή
                                                                    </Button>
                                                                </div>
                                                            </>
                                                            : this.state.maxlimitReplies && !this.state.instance.Completed ? <>

                                                                <Col md="12" style={{ textAlign: 'center' }}>
                                                                    <Button
                                                                        color='success'
                                                                        size="sm"
                                                                        disabled={this.state.blocked == 1}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => { this.acceptAnswer() }}
                                                                    >
                                                                        Αποδοχή Απάντησης και <br />Αίτημα προς Ολοκλήρωση
                                                                    </Button>

                                                                    <br />
                                                                    <br />

                                                                    <Button
                                                                        // href="/admin/instancesettings"
                                                                        // style={{ backgroundColor: this.state.yellow }}
                                                                        // color={this.state.yellow}
                                                                        color='danger'
                                                                        size="sm"
                                                                        disabled={this.state.blocked == 1}
                                                                        title="Εαν πατήσετε απόρριψη το αίτημα αυτό θα απορριφθεί και θα παραμείνει σε καράσταση Απόρριψης. Εαν θέλετε μπορείτε να δημιουργήσετε νέο αίτημα παραπόνου."
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => { this.alisverisiRejected() }}
                                                                    >
                                                                        Απόρριψη Απάντησης και <br />Κλείσιμο Αιτήματος
                                                                    </Button>
                                                                    {/* {this.state.blocked == 1 ?
                                    <>
                                      <br></br>
                                      <br></br>
                                      <Button
                                        color='#26575E'
                                        size="sm"
                                        style={{ width: '100%' }}
                                      >
                                        Μετάβαση Παραπόνου <br />σε Ενεργειακό Διαμεσολαβητή
                                      </Button>
                                      <br />
                                      <p>Το αίτημα σας θα καταγραφεί ως ΤΕΛΙΚΗ ΑΠΑΝΤΗΣΗ ΜΗ ΑΠΟΔΕΚΤΗ ΑΠΟ ΤΟΝ ΚΑΤΑΝΑΛΩΤΗ. Εάν έχετε νέα στοιχεία προς κατάθεση, παρακαλούμε καταθέστε νέο αίτημα παραπόνου</p>
                                    </>
                                    : <> </>} */}
                                                                </Col>
                                                            </> :
                                                                <>
                                                                    {!this.state.instance.Completed ?
                                                                        <Col md="12" style={{ textAlign: 'center' }}>
                                                                            <Button
                                                                                color='success'
                                                                                size="sm"
                                                                                style={{ width: '100%' }}
                                                                                disabled={this.state.blocked == 1}
                                                                                onClick={() => { this.acceptAnswer() }}
                                                                            >
                                                                                Αποδοχή Απάντησης και <br />Αίτημα προς Ολοκλήρωση
                                                                            </Button>

                                                                            <br />
                                                                            <br />

                                                                            <Button
                                                                                // href="/admin/instancesettings"
                                                                                // style={{ backgroundColor: this.state.yellow }}
                                                                                // color={this.state.yellow}
                                                                                color='danger'
                                                                                size="sm"
                                                                                style={{ width: '100%' }}
                                                                                disabled={this.state.blocked == 1}
                                                                                onClick={() => { this.GiveAnswer() }}
                                                                            >
                                                                                Απόρριψη Απάντησης και <br />Αποστολή Νέου Σχολίου
                                                                            </Button>

                                                                            {/* {this.state.blocked == 1 ?
                                        <>
                                          <br></br>
                                          <p>Το αίτημα σας θα καταγραφεί ως ΤΕΛΙΚΗ ΑΠΑΝΤΗΣΗ ΜΗ ΑΠΟΔΕΚΤΗ ΑΠΟ ΤΟΝ ΚΑΤΑΝΑΛΩΤΗ. Εάν έχετε νέα στοιχεία προς κατάθεση, παρακαλούμε καταθέστε νέο αίτημα παραπόνου</p>
                                          <br></br>
                                          <Button
                                            href={'/consumer/newinstanceenomos/' + this.state.instance.Id + '/'}
                                            color='warning'
                                            size="sm"
                                            style={{ width: '100%' }}
                                          >
                                            Μετάβαση Παραπόνου <br />σε Ενεργειακό Διαμεσολαβητή
                                          </Button>
                                          <br></br>
                                          <p>Έχει ολοκληρωθεί ο κύκλος των τριών (3) απαντήσεων από τον πάροχο/διαχειριστή στον οποίο υποβάλλατε το αίτημα σας. Με δεδομένο ότι το εν λόγω αίτημα εμπίπτει στις κατηγορίες αιτημάτων που δύνανται να μεταβούν σε διαμεσολάβηση, μπορείτ να συνεχίσετε επιλέγοντας "Μετάβαση Παραπόνου σε Ενεργειακό Διαμεσολαβητή"</p>

                                        </>
                                        : <> </>} */}
                                                                        </Col> : <>

                                                                            {/* {
                                        this.state.declinedbyConsumer == true ?
                                          <>
                                            <br></br>
                                            <Button
                                              href={'/consumer/newinstanceenomos/' + this.state.instance.Id + '/'}
                                              color='warning'
                                              size="sm"
                                              style={{ width: '100%' }}
                                            >
                                              Μετάβαση Παραπόνου <br />σε Ενεργειακό Διαμεσολαβητή
                                            </Button>
                                            <br></br>
                                            <p>Έχει ολοκληρωθεί ο κύκλος των τριών (3) απαντήσεων από τον πάροχο/διαχειριστή στον οποίο υποβάλλατε το αίτημα σας. Με δεδομένο ότι το εν λόγω αίτημα εμπίπτει στις κατηγορίες αιτημάτων που δύνανται να μεταβούν σε διαμεσολάβηση, μπορείτ να συνεχίσετε επιλέγοντας "Μετάβαση Παραπόνου σε Ενεργειακό Διαμεσολαβητή"</p>

                                          </>
                                          : <></>
                                      } */}
                                                                        </>}
                                                                </>
                                                        }
                                                    </Row>
                                                </> : <></>
                                            }
                                            <br />
                                            <br />
                                            <div className="pl-lg-4" style={{ width: '100%' }}>
                                                <Row>
                                                    <Col md="6">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Εγγραφα Παραληπτη
                                                        </h6>
                                                    </Col>
                                                    {this.state.receiverFiles.length > 1 &&
                                                        <>
                                                            <Col md="6">
                                                                <Button
                                                                    color="success"
                                                                    size='sm'
                                                                    onClick={() => { this.downloadAllFiles(this.state.receiverFiles) }}
                                                                >
                                                                    Λήψη όλων
                                                                </Button>
                                                            </Col>
                                                        </>}

                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Ονομα αρχειου</th>
                                                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                <th scope="col">Ενεργειες</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.receiverFiles.map((dynamicFiles) =>
                                                                    <tr>
                                                                        <td>{dynamicFiles.FileName}</td>
                                                                        <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                                                        <td>
                                                                            <Button
                                                                                color="primary"
                                                                                href={dynamicFiles.Link}
                                                                                target="_blank"
                                                                                title="Προβολή"
                                                                                className="btn-sm btn-primary"
                                                                            >
                                                                                <i className="fas fa-eye" />
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => { this.handleDownload(dynamicFiles.Link, dynamicFiles.Type, dynamicFiles.FileName) }}
                                                                                color="success"
                                                                                size='sm'
                                                                                title="Λήψη"
                                                                            >
                                                                                <i className="fas fa-download" />
                                                                            </Button>
                                                                            {this.state.user.PersonRole == 'Admin' ?
                                                                                <>
                                                                                    <Button
                                                                                        color="danger"
                                                                                        onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                        size="sm"
                                                                                        title="Διαγραφή"
                                                                                    >
                                                                                        <i className="fas fa-trash" />
                                                                                    </Button>
                                                                                </> : <> </>}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col> : <></>}
                        </Row>
                    </Container>
                </>
            );
        }
    }
}

export default ConsumerInstanceEnomos;
